import React from 'react'
import { CreateStateFilingSideBarProps } from '../types'
import IntakeSidebarContent from './IntakeSidebarContent'
import Link from './System/Link'

const title = 'Register in a new state'

const CreateStateFilingSideBar = ({
  hasStockTablePage = false,
  showPaymentInformation = false,
  showSOSPages = false,
  showPayrollReports = false,
  hasDynamicStatePage = true,
  localJurisdictionRegistrations = [],
  current = 0,
  gustoIntake = false,
  brandingOptions,
  shouldShowAdditionalCompanyDetails = true
}: CreateStateFilingSideBarProps): JSX.Element => {
  const content = gustoIntake ? (
    <span>
      To complete the registration process, we need some info about your
      business. This should take 5-10 minutes.
      <br />
      <br />
      Need help?{' '}
      <Link
        target='_blank'
        href='https://help.middesk.com/hc/en-us/articles/30841863488411-Gusto-Middesk'
      >
        Visit the Middesk Help Center
      </Link>
    </span>
  ) : (
    <p>
      <span>
        In order to complete the registration process, we need the following
        information about your business.
      </span>
      {!brandingOptions?.hide_support_link && (
        <span>
          {' '}
          If you need help on the registration, see{' '}
          <Link target='_blank' href='https://help.middesk.com/hc/en-us'>
            Middesk&apos;s Help Center
          </Link>
          .
        </span>
      )}
    </p>
  )

  const RegistrationInformationGroup = {
    title: 'Registration information',
    page: 2,
    pageCount: 1
  }

  const ContactInformationGroup = {
    title: 'Contact information',
    page:
      RegistrationInformationGroup.page +
      RegistrationInformationGroup.pageCount,
    pageCount: 1
  }

  const BusinessInformationGroup = {
    title: 'Business information',
    page: ContactInformationGroup.page + ContactInformationGroup.pageCount,
    pageCount: shouldShowAdditionalCompanyDetails ? 3 : 2
  }

  const LeadershipInformationGroup = {
    title: 'Officer / Owner information',
    page: BusinessInformationGroup.page + BusinessInformationGroup.pageCount,
    pageCount: [1, hasStockTablePage].filter(x => x).length
  }

  const StateInformationGroup = {
    title: 'State information',
    page:
      LeadershipInformationGroup.page + LeadershipInformationGroup.pageCount,
    pageCount: [1, showPayrollReports].filter(x => x).length
  }

  const statePageOffset = hasDynamicStatePage
    ? StateInformationGroup.page + StateInformationGroup.pageCount
    : StateInformationGroup.page

  const localGroups = localJurisdictionRegistrations.map(
    (localJurisdiction, index) => {
      return {
        title: `${localJurisdiction.jurisdictionLabel} information`,
        page: statePageOffset + index,
        pageCount: 1
      }
    }
  )

  const ReviewAndSubmitGroup = {
    title: gustoIntake ? 'Review information' : 'Review & submit',
    page: statePageOffset + localGroups.length,
    pageCount: [1, showPaymentInformation].filter(x => x).length
  }

  const SosGroup = {
    title: 'Secretary of State',
    page: ReviewAndSubmitGroup.page + ReviewAndSubmitGroup.pageCount
  }

  const GustoSubmission = {
    title: 'Submit order',
    page: ReviewAndSubmitGroup.page + ReviewAndSubmitGroup.pageCount
  }

  const groups = [
    RegistrationInformationGroup,
    ContactInformationGroup,
    BusinessInformationGroup,
    LeadershipInformationGroup,
    ...(hasDynamicStatePage ? [StateInformationGroup] : []),
    ...localGroups,
    ReviewAndSubmitGroup,
    ...(showSOSPages ? [SosGroup] : []),
    ...(gustoIntake ? [GustoSubmission] : [])
  ]

  return (
    <>
      <IntakeSidebarContent
        {...{
          title,
          content,
          groups,
          current,
          gustoIntake,
          brandingOptions
        }}
      />
    </>
  )
}

export default CreateStateFilingSideBar
