import React from 'react'
import { useFormikContext } from 'formik'
import {
  InfoRequestValues,
  OutboundAttachment,
  DescriptionNote
} from '../../pages/InfoRequestForm'
import { InfoRequestItem, Question } from '../../types'
import { getQuestionComponent } from '../../pages/DynamicStatePage'

export type RegistrationQuestionInfoRequestProps = {
  item: InfoRequestItem
  index: number
}

export const RegistrationQuestionInfoRequest = ({
  item,
  index
}: RegistrationQuestionInfoRequestProps): JSX.Element => {
  const { values } = useFormikContext<InfoRequestValues>()
  const name = `items[${index}].value`
  const questionValue = String(values.items[index].value)
  const question = {
    ...item.question,
    key: item.question?.key || name,
    label: item.title || item.question?.label,
    placeholder: item.placeholder || item.question?.placeholder
  } as Question

  const component = getQuestionComponent(
    question,
    name,
    questionValue,
    index,
    values,
    undefined
  )

  return (
    <div key={item.id}>
      {component}
      <DescriptionNote description={item.description} />
      <OutboundAttachment attachment={item.outbound_attachment} />
    </div>
  )
}
