import React, { FC } from 'react'
import styled from 'styled-components'
import { COLORS } from './System/Colors'
import { TYPOGRAPHY_WEIGHTS } from './System/Typography'
import { RegistrationPrice } from '../types'
import { TextTooltip } from '@middesk/components'

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: ${COLORS.graphite};
`

const TotalItem = styled(LineItem)`
  padding-top: 10px;
  margin-top: 10px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  border-top: 2px solid ${COLORS.dawn};
`

type Props = {
  taxRegistrationPrices: RegistrationPrice[]
}

const TaxRegistrationLineItems: FC<Props> = ({
  taxRegistrationPrices
}: Props) => {
  const totalPrice = taxRegistrationPrices.reduce(
    (partialSum: number, item: RegistrationPrice) =>
      (partialSum += item.amount_cents),
    0
  )

  return (
    <div>
      {taxRegistrationPrices.map((item: RegistrationPrice, index: number) => {
        const { label, amount_cents, tooltip } = item

        return (
          <LineItem key={`tax-item-price-${index}`}>
            <span>
              {tooltip ? (
                <TextTooltip trigger={label} content={tooltip} />
              ) : (
                label
              )}
            </span>
            <span>${amount_cents / 100}</span>
          </LineItem>
        )
      })}
      <TotalItem>
        <span>Total</span>
        <span>${totalPrice / 100}</span>
      </TotalItem>
    </div>
  )
}

export default TaxRegistrationLineItems
