import React, { useContext } from 'react'
import {
  FOREIGN_QUALIFICATION_TYPE,
  FQ_STATE_FEES_MESSAGE
} from '../../lib/constants'
import { useFormikContext } from 'formik'
import { FormValues, TransactionalPricingData } from '../../types'
import { RadioField, TextField, TextTooltip, theme } from '@middesk/components'
import { fqLineItems } from '../../lib/helpers'
import { StyledContainer } from './AgenciesDescription'
import { StyledContainer as OptionsContainer } from './RegistrationOptions'
import { mapStateAbbreviationToName } from '../StateFilter'
import { ApplicationContext } from '../../contexts/ApplicationProvider'
import { capitalize, get } from 'lodash'

const { spacing } = theme

const ForeignQualificationOptions = ({
  selectedJurisdictions,
  pricingData
}: {
  selectedJurisdictions: string[]
  pricingData?: TransactionalPricingData
}) => {
  const {
    setFieldValue,
    values: { foreign_qualifications }
  } = useFormikContext<FormValues>()
  const { state: filingState } = useContext(ApplicationContext)

  const stateStr =
    foreign_qualifications && foreign_qualifications[0]
      ? mapStateAbbreviationToName(foreign_qualifications[0].state) + ' '
      : ''

  const isTransfer =
    foreign_qualifications &&
    foreign_qualifications[0] &&
    foreign_qualifications[0].transfer

  const setTransfer = (transfer: boolean) =>
    setFieldValue('foreign_qualifications[0].transfer', transfer)

  const fqPrice = (transfer: boolean) =>
    pricingData && filingState
      ? fqLineItems(pricingData, filingState, transfer).reduce(
          (sum, item) => sum + item.amount_cents,
          0
        ) / 100
      : 0

  const pricingDataMessage = (
    pricingData: TransactionalPricingData,
    transfer: boolean
  ) => {
    const entity_prices =
      pricingData.fq_registration_current_prices[filingState][
        transfer ? 'transfer' : 'new'
      ].entity_prices
    if (!entity_prices) {
      return FQ_STATE_FEES_MESSAGE
    }
    return (
      <div>
        {FQ_STATE_FEES_MESSAGE}
        <div
          style={{ marginLeft: spacing.xxsmall, marginTop: spacing.compact }}
        >
          <b>State Fees:</b>
        </div>
        <ul style={{ marginTop: spacing.xxsmall }}>
          {Object.keys(entity_prices).map(key => {
            let value = get(entity_prices, key)
            if (typeof value === 'number') {
              value = `~$${value}`
            }
            return (
              <li key={key}>
                <b>{capitalize(key.replaceAll('_', ' '))}</b>: {value}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return selectedJurisdictions.includes(FOREIGN_QUALIFICATION_TYPE) ? (
    <OptionsContainer>
      <RadioField
        label={
          <div className='label'>
            Register for foreign qualification{' '}
            {pricingData ? (
              <TextTooltip trigger={`(est. $${fqPrice(false)})`}>
                <TextTooltip.Content>
                  {pricingDataMessage(pricingData, false)}
                </TextTooltip.Content>
              </TextTooltip>
            ) : (
              ''
            )}
          </div>
        }
        checked={!isTransfer}
        value={'false'}
        name={'foreign_qualifications[0].transfer'}
        onChange={() => setTransfer(false)}
        style={{ marginBottom: spacing.small }}
      />
      <StyledContainer>
        <div className='agency'>
          <h1>{stateStr}Secretary of State</h1>
          <ul className='items'>
            <li>Agency registration</li>
            <li>Registered agent services</li>
          </ul>
        </div>
      </StyledContainer>
      <RadioField
        label={
          <div className='label'>
            Transfer existing foreign qualification{' '}
            {pricingData ? (
              <TextTooltip trigger={`(est. $${fqPrice(true)})`}>
                <TextTooltip.Content>
                  {pricingDataMessage(pricingData, true)}
                </TextTooltip.Content>
              </TextTooltip>
            ) : (
              ''
            )}
          </div>
        }
        checked={isTransfer}
        value={'true'}
        name={'foreign_qualifications[0].transfer'}
        onChange={() => setTransfer(true)}
        style={{ marginBottom: spacing.small }}
      />
      <StyledContainer>
        <div className='agency'>
          <h1>{stateStr}Secretary of State</h1>
          <ul className='items'>
            <li>Account management update</li>
            <li>Registered agent services</li>
          </ul>
        </div>
        {isTransfer && (
          <div style={{ marginTop: spacing.compact }}>
            <TextField
              placeholder='Enter Secretary of State registration id...'
              name='foreign_qualifications[0].sos_registration_id'
              label='Secretary of State registration id'
            />
          </div>
        )}
      </StyledContainer>
    </OptionsContainer>
  ) : (
    <></>
  )
}

export default ForeignQualificationOptions
