import { DropzoneFile, TextField } from '@middesk/components'
import { encode } from 'base64-arraybuffer'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import ActionFooter from '../components/ActionFooter'
import Address from '../components/Address'
import CheckboxLabel from '../components/CheckboxLabel'
import JobTitle from '../components/JobTitle'
import SignaturePad from '../components/SignaturePad'
import SignaturePrefill from '../components/SignaturePrefill'
import SignatureUploader from '../components/SignatureUploader'
import Link from '../components/System/Link'
import { Body } from '../components/System/Typography'
import {
  BASE_64_IDENTIFIER,
  isLlcEntityType,
  disableScrollAction
} from '../lib/helpers'
import { FormValues, Individual } from '../types'
import { theme } from '@middesk/components'
import { GapWrapper } from '../components/Page'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { get, isEmpty, isString } from 'lodash'
import moment from 'moment'
import { INVALID_SSNS, JOB_TITLES, ALL_JOB_TITLES } from '../lib/constants'
import Checkbox from '../components/System/Checkbox'

const { spacing, colors, typography } = theme

export const SIGN_MODE = 'sign'
export const UPLOAD_SIGN_MODE = 'upload_sign'

const ContentWrapper = styled.div`
  margin: ${spacing.small} 0;
`

export const ErrorContainer = styled.div`
  background: ${colors.redLight};
  color: ${colors.graphite};
  padding: 14px;
  margin-top: 10px;
  border-radius: 10px;
  color: ${colors.red};
  font-size: 14px;
`

const GenericIndividualErrorMessage = () => (
  <ErrorContainer>Please review validation errors</ErrorContainer>
)

export const individualSchema = yup.object().shape({
  name: yup.string().required('Name required').nullable(),
  address: yup.object().when('redacted', {
    is: redacted => !redacted,
    then: yup.object({
      address_line1: yup.string().required('Address required'),
      address_line2: yup.string().optional().nullable(),
      city: yup.string().required('City required'),
      state: yup.string().required('State required'),
      postal_code: yup.string().required('ZIP code required')
    })
  }),
  dob: yup.date().when('redacted', {
    is: redacted => !redacted,
    then: yup
      .date()
      .required('DOB required')
      .nullable()
      .test(
        'DOB Validity',
        'Date of birth must be less than 150 years ago',
        value =>
          !!value &&
          moment().diff(moment(value), 'year') < 150 &&
          moment(value).isBefore(moment())
      )
  }),
  titles: yup.array().of(yup.string()).min(1, 'Missing title').nullable(),
  ssn: yup.string().when('redacted', {
    is: redacted => !redacted,
    then: yup
      .string()
      .test(
        'len',
        'SSN must be 9 characters',
        value => !!value && value.replace(/\D+/g, '').length === 9
      )
      .test(
        'valid_ssn',
        'This must be a valid SSN',
        value => !!value && checkForInvalidSSN(value)
      )
      .required('SSN required')
      .nullable()
  }),
  signatory: yup.boolean().optional().nullable(),
  director: yup.boolean().optional().nullable(),
  signature: yup.string().optional().nullable(),
  email: yup
    .string()
    .email('Email must be valid')
    .required('Email required')
    .nullable(),
  formation_date: yup.date().nullable(),
  hired_date: yup
    .mixed()
    .test('isValid', 'Hired date is invalid', function (value) {
      const { path, createError, parent } = this

      if (value) {
        if (!moment(value).isValid()) {
          return createError({
            path,
            message: 'Hired date is required'
          })
        }

        if (parent.formation_date) {
          if (new Date(value) < new Date(parent.formation_date)) {
            return createError({
              path,
              message: `Hired date must be on or after the business formation date: ${moment(
                parent.formation_date
              ).format('MM/DD/YYYY')}`
            })
          }
        }

        if (moment(value).isAfter(moment())) {
          return createError({
            path,
            message: 'Hired date must be before today'
          })
        }
      } else {
        return createError({
          path,
          message: 'Hired date is required'
        })
      }

      return true
    }),
  ownership_percentage: yup
    .number()
    .min(0, 'Ownership percentage must be greater than or equal to 0')
    .max(100, 'Ownership percentage must be less than or equal to 100')
    .required('Ownership percentage is required')
    .nullable()
})

export const editingIndividualSchema = yup.object().shape({
  editing_owner: individualSchema.shape({
    authorized: yup.boolean().when('signature', {
      is: (signature: string) => signature && signature.length > 0,
      then: yup
        .boolean()
        .oneOf([true], 'Signature Authorization required')
        .required()
    })
  })
})

const checkForInvalidSSN = (ssn: string | number) => {
  const parsedSsn = String(ssn).replaceAll('-', '')
  const threePartSSN = [
    parsedSsn.slice(0, 3),
    parsedSsn.slice(3, 5),
    parsedSsn.slice(5, 9)
  ]

  if (threePartSSN[0] === '000' || threePartSSN[0] === '666') return false
  if (threePartSSN[1] === '00') return false
  if (threePartSSN[2] === '0000') return false
  if (INVALID_SSNS.includes(parsedSsn)) return false
  return true
}

export const FieldError = ({
  fieldName,
  showFullValidation
}: {
  fieldName: string
  showFullValidation: boolean
}): JSX.Element | null => {
  const { errors, touched } = useFormikContext()

  const error = get(errors, fieldName)
  const isTouched = get(touched, fieldName)

  if (!error) return null
  if (!showFullValidation && !isTouched) return null

  return (
    <div style={{ color: colors.red, fontSize: typography.sizes.small }}>
      {get(errors, fieldName)}
    </div>
  )
}

const EditingIndividual = ({
  onAddIndividual,
  preFillSignature,
  setPreFillSignature,
  onCancel,
  showPoweredByMiddesk,
  progress
}: {
  onAddIndividual: any
  preFillSignature: any
  setPreFillSignature: any
  onCancel: any
  showPoweredByMiddesk?: boolean
  progress?: string
}): JSX.Element => {
  const { values, setFieldValue, errors } = useFormikContext<FormValues>()

  const [signatureFile, setSignatureFile] = useState<DropzoneFile[]>([])
  const [signatureMode, setSignatureMode] = useState<string>(SIGN_MODE)
  const [signature, setSignature] = useState<string | undefined>('')
  const [showFullValidation, setShowFullValidation] = useState<boolean>(false)

  useEffect(() => {
    // For Validation
    setFieldValue(`editing_owner.formation_date`, values.formation_date)
  }, [values.formation_date])

  useEffect(() => {
    setSignature('')
    setSignatureFile([])
  }, [signatureMode])

  useEffect(() => {
    !preFillSignature && setFieldValue('editing_owner.signature', signature)
  }, [signature])

  useEffect(() => {
    setSignatureMode(SIGN_MODE)
  }, [(values.editing_owner as Individual)?.signatory])

  useEffect(() => {
    if (signatureFile.length === 0 && signature) {
      setSignature('')
    }
  }, [signatureFile])

  const handleDrop = (data: DropzoneFile[]) => {
    setSignatureFile([data[0]])
    isString(data[0].data)
      ? setSignature(`${BASE_64_IDENTIFIER}${data[0].data}`)
      : setSignature(`${BASE_64_IDENTIFIER}${encode(data[0].data)}`)
  }

  const handleSetFiles = (files: DropzoneFile[]) => {
    setSignatureFile(files.length > 0 ? [files[0]] : [])
  }

  const validateThenSave = (e: any) => {
    e.preventDefault()
    setShowFullValidation(true)

    if (isEmpty(errors)) {
      onAddIndividual(e)
    }
  }

  const jobTitles = isLlcEntityType(values.entity_type)
    ? ALL_JOB_TITLES
    : JOB_TITLES

  return (
    <>
      <GapWrapper>
        <div>
          <TextField
            autoFocus
            name={`editing_owner.name`}
            label='Legal name of person'
            placeholder='Name'
          />
          <FieldError
            showFullValidation={showFullValidation}
            fieldName='editing_owner.name'
          />
        </div>
        <div>
          <TextField
            name={`editing_owner.email`}
            label='Email'
            placeholder='Enter contact email'
          />
          <FieldError
            fieldName='editing_owner.email'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <JobTitle
            titleOptions={Object.values(jobTitles)}
            name={`editing_owner.titles`}
          />
          <FieldError
            fieldName='editing_owner.titles'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            type='ssn'
            name={`editing_owner.ssn`}
            label='Social Security Number (Or ITIN if this individual does not have a SSN)'
            placeholder='XXX-XX-XXXX'
          />
          <FieldError
            fieldName='editing_owner.ssn'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            label='Date of birth'
            placeholder='MM/DD/YYYY'
            type='date'
            name={`editing_owner.dob`}
          />
          <FieldError
            fieldName='editing_owner.dob'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            label='Ownership percentage'
            placeholder='Enter a percentage'
            type='number'
            min='0'
            max='100'
            name={`editing_owner.ownership_percentage`}
            onFocus={disableScrollAction}
          />
          <FieldError
            fieldName='editing_owner.ownership_percentage'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            label='Date hired'
            placeholder='MM/DD/YYYY'
            type='date'
            name={`editing_owner.hired_date`}
          />
          <FieldError
            fieldName='editing_owner.hired_date'
            showFullValidation={showFullValidation}
          />
        </div>

        <Address
          name={`editing_owner.address`}
          label='Home address'
          showErrorMessage
        />
      </GapWrapper>
      <ContentWrapper>
        {isLlcEntityType(values.entity_type) ? (
          <Checkbox
            label={
              <CheckboxLabel>
                This individual is an LLC manager or member.
              </CheckboxLabel>
            }
            checked={(values.editing_owner as Individual)?.llc_owner}
            name={`editing_owner.llc_owner`}
            onClick={() => {
              const checked = (values.editing_owner as Individual)?.llc_owner
              setFieldValue(`editing_owner.llc_owner`, !checked)
            }}
          />
        ) : (
          <Checkbox
            label={
              <CheckboxLabel>
                This individual is on the board of directors.
              </CheckboxLabel>
            }
            checked={(values.editing_owner as Individual)?.director}
            name={`editing_owner.director`}
            onClick={() => {
              const checked = (values.editing_owner as Individual)?.director
              setFieldValue(`editing_owner.director`, !checked)
            }}
          />
        )}
        <Checkbox
          label={
            <CheckboxLabel>
              This is the business signatory (they must be listed as an officer
              of the business).
            </CheckboxLabel>
          }
          checked={(values.editing_owner as Individual)?.signatory}
          name={`editing_owner.signatory`}
          onClick={() => {
            const checked = (values.editing_owner as Individual)?.signatory
            if (checked) {
              //unchecking the box
              setFieldValue(`editing_owner.authorized`, false)
              setFieldValue(`editing_owner.signature`, '')
            }
            setFieldValue(`editing_owner.signatory`, !checked)
          }}
        />
      </ContentWrapper>
      {(values.editing_owner as Individual)?.signatory ? (
        <>
          <Body>
            Providing your signature below helps speed up the registration
            process by allowing Middesk to complete multiple registrations using
            one signature. If you are not the signatory, we will reach out to
            the signatory to obtain their signature.
          </Body>
          <br />
        </>
      ) : (
        ''
      )}
      <br />
      {(values.editing_owner as Individual)?.signatory ? (
        signatureMode === UPLOAD_SIGN_MODE ? (
          <>
            <SignatureUploader
              onDrop={handleDrop}
              onSetFiles={handleSetFiles}
              files={signatureFile}
            />
            {signatureFile.length === 0 ? (
              <ActionFooter separated={false}>
                <Link onClick={() => setSignatureMode(SIGN_MODE)}>
                  Or sign here
                </Link>
              </ActionFooter>
            ) : (
              ''
            )}
          </>
        ) : (
          <ContentWrapper>
            {preFillSignature ? (
              <SignaturePrefill
                signature={preFillSignature}
                onClear={() => {
                  setPreFillSignature('')
                  setSignature('')
                  setFieldValue('editing_owner.signature', '')
                  setFieldValue('editing_owner.authorized', false)
                }}
              />
            ) : (
              <SignaturePad
                {...{
                  label: 'Sign here',
                  onFinish: e => setSignature(e),
                  onClear: () => {
                    setSignature('')
                    setFieldValue('editing_owner.signature', '')
                    setFieldValue('editing_owner.authorized', false)
                  }
                }}
              >
                <Link onClick={() => setSignatureMode(UPLOAD_SIGN_MODE)}>
                  Or upload a signature
                </Link>
              </SignaturePad>
            )}
          </ContentWrapper>
        )
      ) : (
        ''
      )}
      <br />
      {(values.editing_owner as Individual)?.signatory &&
        (values.editing_owner as Individual)?.signature && (
          <Checkbox
            label={
              <CheckboxLabel>
                I authorize Middesk to use my signature for filings related to
                the Secretary of State and Payroll Tax.
              </CheckboxLabel>
            }
            checked={(values.editing_owner as Individual)?.authorized}
            name={`editing_owner.authorized`}
            onClick={() => {
              const checked = (values.editing_owner as Individual)?.authorized
              setFieldValue(`editing_owner.authorized`, !checked)
            }}
          />
        )}
      {showFullValidation && !isEmpty(errors) && (
        <GenericIndividualErrorMessage />
      )}
      <NewRegistrationIntakeFooter
        {...{
          values,
          onCancel: onCancel,
          isSubmitting: false,
          isDisabled: showFullValidation && !isEmpty(errors),
          onClick: validateThenSave,
          progress,
          title: 'Officer / Owner information',
          submitText: 'Add individual',
          showPoweredByMiddesk
        }}
      />
    </>
  )
}

export default EditingIndividual
