import React from 'react'
import styled from 'styled-components'
import { Modal, Loader, theme } from '@middesk/components'
import { MAX_WIDTH_MOBILE } from '../../components/Body'

const { spacing, colors, typography } = theme

const StyledModal = styled(Modal)<{ gusto: boolean; loading: boolean }>`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 1.5625rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 600px;
  outline: none;
  overflow: auto;
  width: 90%;

  ${props =>
    props.gusto && !props.loading
      ? `
    div:last-child {
      @media (max-width: ${MAX_WIDTH_MOBILE}) {
        flex-direction: column;

        button {
          margin-bottom: ${spacing.compact};
        }
      }

      button:last-child {
        border-color: #E34234;
        background-color: #E34234;

        &:hover {
          border-color: #A42A04;
          background-color: #A42A04;
        }
      }
    }
    `
      : ''}

  ${props =>
    props.gusto && props.loading
      ? `
    div:last-child {
      button {
        color: ${colors.white};
        cursor: default;
        border-color: ${colors.frost};
        background-color: ${colors.frost};

        &:hover {
          border-color: ${colors.frost};
          background-color: ${colors.frost};
        }
      }
    }
    `
      : ''}
`

const ListItem = styled.li<{ highlight: boolean }>`
  font-size: ${typography.sizes.medium}px;
  ${props => (props.highlight ? `color: ${colors.red}` : '')};
`

const List = styled.ul`
  padding-left: ${spacing.large};
`

const ExtraInformation = styled.div`
  margin-top: ${spacing.compact};
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing.large};
`

const UnsupportedModal = ({
  registrationErrors,
  isOpen,
  confirmLabel,
  gustoIntake,
  acceptUnsupported,
  cancelLoading,
  hideModal
}: {
  registrationErrors: string[]
  isOpen: boolean
  confirmLabel: string
  gustoIntake: boolean
  acceptUnsupported: () => void
  cancelLoading: boolean
  hideModal: () => void
}) => (
  <StyledModal
    gusto={gustoIntake}
    loading={cancelLoading}
    title='Unsupported tax situation'
    isOpen={isOpen}
    isConfirmDisabled={cancelLoading}
    confirmLabel={
      gustoIntake ? 'Cancel order & continue to Gusto' : confirmLabel
    }
    closeLabel={gustoIntake ? 'Back' : 'Close'}
    confirm={acceptUnsupported}
    close={() => {
      !cancelLoading && hideModal()
    }}
  >
    <div>
      We&apos;re unable to submit your registration because you selected the
      following:
      <List>
        {registrationErrors.map((error, i) => (
          <ListItem highlight={!gustoIntake} key={`error-${i}`}>
            {error}
          </ListItem>
        ))}
      </List>
      {gustoIntake && (
        <>
          <ExtraInformation>
            If you choose to continue, your registration order will be canceled
            and you will not be able to submit a tax registration order for this
            state again.
          </ExtraInformation>
          <ExtraInformation>
            If you have any further questions, feel free to send us a note at{' '}
            <a href='mailto: taxregistrations@middesk.com'>
              taxregistrations@middesk.com
            </a>
            .
          </ExtraInformation>
          {cancelLoading && (
            <LoaderWrapper>
              <Loader size='medium' />
            </LoaderWrapper>
          )}
        </>
      )}
    </div>
  </StyledModal>
)

export default UnsupportedModal
