import React, { useContext, useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import api from '../lib/api'
import Loader from '../components/System/Loader'
import { Page, PageProps } from '../components/Page'
import PaymentCapture, { trackUser } from '../components/PaymentCapture'
import { AuthContext } from '../contexts/AuthProvider'
import { FormValues } from '../types'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { fqLineItemsFromApplication } from '../lib/helpers'
import TaxRegistrationLineItems from '../components/TaxRegistrationLineItems'

const LineItems = styled.div`
  padding-top: 5px;
`

const validationSchema = yup.object().shape({
  accept_tos: yup.bool().oneOf([true], 'You Must Accept Payment Usage Terms')
})

const SecretaryOfStatePayment: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  application,
  isSubmitting = false,
  showPoweredByMiddesk,
  progress
}: PageProps) => {
  const { values } = useFormikContext<FormValues>()
  const { internal } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [paymentReady, setPaymentReady] = useState(false)
  const [error, setError] = useState('')
  const [newUser, setNewUser] = useState(false)
  const [setupSecret, setSetupSecret] = useState('')

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)

    if (internal) {
      setLoading(false)
      return
    }

    api
      .get('/ajax/setup_card')
      .then(json => setSetupSecret(json.client_secret))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (application?.company_id) {
      api
        .get(`/v1/agent/companies/${application.company_id}/tax_registrations`)
        .then(json => setNewUser(json.data && json.data.length === 0))
    }
  }, [application])

  const applicationUpdate = () => {
    if (!internal) {
      trackUser(newUser, 'new-application')
    }

    setSubmitting(false)

    // Submit Application
    return onNext(values, true)
  }

  const onClick = (
    e:
      | React.MouseEventHandler<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>
  ) => {
    const formEvent = e as React.FormEvent<HTMLFormElement>
    formEvent.preventDefault()

    setSubmitting(true)
  }

  const foreignQualificationLineItems = () => {
    return (
      <LineItems>
        <TaxRegistrationLineItems
          taxRegistrationPrices={fqLineItemsFromApplication(
            application,
            !!(values.foreign_qualifications || [])[0]?.transfer
          )}
        />
      </LineItems>
    )
  }

  return (
    <Loader {...{ loading }}>
      <PaymentCapture
        setPaymentReady={setPaymentReady}
        submitting={submitting}
        setSubmitting={setSubmitting}
        applicationUpdate={applicationUpdate}
        error={error}
        setError={setError}
        setupSecret={setupSecret}
        lineItems={foreignQualificationLineItems()}
        label={
          "By clicking this box, you authorize Middesk to charge your credit card. You'll be billed once we've set up your Secretary of State account."
        }
      />
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled: !validationSchema.isValidSync(values) || !paymentReady,
          onClick,
          progress,
          title: 'Payment information',
          showPoweredByMiddesk,
          submitText: 'Complete Order'
        }}
      />
    </Loader>
  )
}

SecretaryOfStatePayment.pageName = 'SecretaryOfStatePayment'
SecretaryOfStatePayment.title = () => 'Payment information'
SecretaryOfStatePayment.description = () =>
  'Enter your billing information to submit your state filing.'

export default SecretaryOfStatePayment
