import React, { useContext, useEffect } from 'react'
import { theme } from '@middesk/components'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { Page, PageProps } from '../components/Page'
import { mapStateAbbreviationToName } from '../components/StateFilter'
import SPACING from '../components/System/Spacing'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { FormValues } from '../types'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { AccountContext } from '../contexts/AccountProvider'
import { fqLineItemsFromApplication, isLlcEntityType } from '../lib/helpers'
import TaxRegistrationLineItems from '../components/TaxRegistrationLineItems'
import { LineItems } from './Payment'

const { spacing, typography } = theme

const IntroText = styled.div`
  display: flex;
  justify-content: left;
  padding-bottom: ${SPACING.small};
`

const StyledRow = styled(Row)`
  padding: ${SPACING.small} 0;
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

const StyledInput = styled.input`
  margin-top: ${SPACING.xsmall};
  margin-right: ${SPACING.small};
  display: block;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: ${spacing.medium};
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: ${spacing.small};
  font-size: ${typography.sizes.medium};
`

const FlexLeftCol = styled(FlexCol)`
  margin-left: ${spacing.medium};
`

const validationSchema = yup.object().shape({
  sos_registration_selection: yup
    .string()
    .required('Please select SOS Registration Option')
    .nullable()
})

const annualFilingEstimate = (state: string, entity_type: string) => {
  switch (state) {
    case 'MO':
      return '$21'
    case 'NM':
      return '$25'
    case 'NJ':
      return '$78'
    case 'VT':
      return isLlcEntityType(entity_type) ? '$140' : '$200'
  }
}

export const annualFilingText = (state: string, entity_type: string) => {
  const estimatedTotal = annualFilingEstimate(state, entity_type)

  return (
    <p>
      After the initial Secretary of State registration is completed, most
      states require ongoing reporting. The reporting cadence varies by state
      and the estimated report fees for this state is <b>{estimatedTotal}</b>.
      The reporting is required to keep your entity in good standing and will be
      auto charged to the card on file when completed.
    </p>
  )
}

const SecretaryOfStateRegistration: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  showSOSPaymentInformation,
  isSubmitting = false,
  error,
  showPoweredByMiddesk,
  progress,
  application
}: PageProps) => {
  const { state } = useContext(ApplicationContext)
  const { requiresSOSPaymentInformation } = useContext(AccountContext)
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const stateName = mapStateAbbreviationToName(state)

  const isSubmitPage = !showSOSPaymentInformation

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)
  }, [])

  const totalBilledText = () => {
    if (requiresSOSPaymentInformation) {
      return <></>
    }

    return (
      <p>
        Note: You&apos;ll be billed once we&apos;ve set up your Secretary of
        State account.
      </p>
    )
  }

  return (
    <>
      <IntroText>
        <span>
          You need to be registered with the{' '}
          <b>{stateName} Secretary of State</b> in order to register for payroll
          taxes. Select from the following options:
        </span>
      </IntroText>

      <StyledRow>
        <StyledCol>
          <StyledInput
            name='sos_registration_selection'
            key={'middesk'}
            checked={values.sos_registration_selection === 'middesk'}
            value='middesk'
            type='radio'
            onChange={() =>
              setFieldValue('sos_registration_selection', 'middesk')
            }
          />
        </StyledCol>
        <Col xs>
          <Row>
            <span>
              I&apos;m <b>not</b> registered and I want to register with Middesk
            </span>
          </Row>
          <LineItems style={{ marginTop: spacing.medium }}>
            <TaxRegistrationLineItems
              taxRegistrationPrices={fqLineItemsFromApplication(
                application,
                !!(values.foreign_qualifications || [])[0]?.transfer
              )}
            />
          </LineItems>
          <FlexRow>
            <FlexLeftCol>
              {annualFilingText(state, values.entity_type)}
            </FlexLeftCol>
          </FlexRow>
          <FlexRow>
            <FlexLeftCol>{totalBilledText()}</FlexLeftCol>
          </FlexRow>
        </Col>
      </StyledRow>

      <StyledRow>
        <StyledCol>
          <StyledInput
            name='sos_registration_selection'
            key={'self_register'}
            checked={values.sos_registration_selection === 'self_register'}
            value='self_register'
            type='radio'
            onChange={() =>
              setFieldValue('sos_registration_selection', 'self_register')
            }
          />
        </StyledCol>
        <Col xs>
          <Row>
            <span>
              I&apos;m <b>not</b> registered and want to handle it on my own
            </span>
          </Row>
        </Col>
      </StyledRow>

      <StyledRow>
        <StyledCol>
          <StyledInput
            name='sos_registration_selection'
            key={'registered'}
            checked={values.sos_registration_selection === 'registered'}
            value='registered'
            type='radio'
            onChange={() =>
              setFieldValue('sos_registration_selection', 'registered')
            }
          />
        </StyledCol>
        <Col xs>
          <Row>
            <span>I&apos;m registered and all set!</span>
          </Row>
        </Col>
      </StyledRow>

      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled:
            !validationSchema.isValidSync(values) ||
            !values.sos_registration_selection,
          onClick: () => onNext(values, isSubmitPage),
          submitText: isSubmitPage ? 'Submit' : 'Continue',
          progress,
          title: 'Secretary of State information',
          showPoweredByMiddesk
        }}
      />
    </>
  )
}

SecretaryOfStateRegistration.validationSchema = validationSchema
SecretaryOfStateRegistration.pageName = 'SecretaryOfStateRegistration'
SecretaryOfStateRegistration.title = () => 'Select your preference'
SecretaryOfStateRegistration.description = () =>
  'Registration with the Secretary of State is required for this state.'

export default SecretaryOfStateRegistration
