import Cookies from 'js-cookie'
import qs from 'qs'

import Env from './env'
import { isGuestAccessiblePath } from './helpers'
import { SANDBOX_DENYLIST } from './constants'

const GUEST_TOKEN_KEY = 'guest_token'
const SANDBOX_MODE_KEY = 'sandbox_mode'
const COOKIE_OPTIONS = Env.isProduction() ? { secure: true } : {}

class API {
  // Authorization header will be set in this order of preference:
  // 1. Passed explicitly by the caller
  // 2. Access token stored in current_user cookie
  // 3. Token set in URL param
  get auth() {
    const headers: {
      Authorization?: string
      'X-Middesk-Use-Guest-Authentication'?: boolean
      'X-Middesk-Use-Passcode-Token'?: boolean
    } = {}

    if (this.usingGuestTokenStrategy()) {
      headers['Authorization'] = `Basic ${btoa(`${this.guestToken()}:`)}`
      headers['X-Middesk-Use-Passcode-Token'] = true
    } else if (this.current_user()) {
      headers['Authorization'] = `Bearer ${this.current_user()}`
    } else if (this.token()) {
      headers['Authorization'] = `Bearer ${this.token()}`
    }
    return headers
  }

  get(path: string, params: any = {}, headers: any = {}) {
    const query = params
      ? `?${qs.stringify(params, { arrayFormat: 'brackets' })}`
      : ''
    return this.request(`${path}${query}`, { method: 'GET', headers })
  }

  current_user() {
    return Cookies.get('current_user')
  }

  guestToken(newValue?: string) {
    if (newValue) {
      Cookies.set(GUEST_TOKEN_KEY, newValue, COOKIE_OPTIONS)
    }
    return Cookies.get(GUEST_TOKEN_KEY)
  }

  removeGuestToken() {
    Cookies.remove(GUEST_TOKEN_KEY)
  }

  token() {
    return qs.parse(document.URL, { ignoreQueryPrefix: true }).token
  }

  removeCurrentUser() {
    Cookies.remove('current_user')
    Cookies.remove('current_user', { domain: 'middesk.com' })
  }

  usingGuestTokenStrategy() {
    return (
      isGuestAccessiblePath(window.location.pathname) && !!this.guestToken()
    )
  }

  setSandboxMode(sandboxMode: boolean) {
    if (sandboxMode) {
      Cookies.set(SANDBOX_MODE_KEY, 'true')
    } else {
      Cookies.remove(SANDBOX_MODE_KEY)
    }
  }

  sandboxMode() {
    return !!Cookies.get(SANDBOX_MODE_KEY)
  }

  sandboxHeader(path: string) {
    if (
      !this.sandboxMode() ||
      SANDBOX_DENYLIST.some(name => path.startsWith(name))
    ) {
      return {}
    }

    return { 'X-Middesk-Sandbox-Mode': true }
  }

  post(path: string, body: any, headers?: any) {
    return this.request(path, { method: 'POST', body, headers })
  }

  put(path: string, body: any, headers?: any) {
    return this.request(path, { method: 'PUT', body, headers })
  }

  patch(path: string, body: any, headers?: any) {
    return this.request(path, { method: 'PATCH', body, headers })
  }

  delete(path: string, body?: any, headers?: any) {
    return this.request(path, { method: 'DELETE', body, headers })
  }

  handleAccessToken(res: Response) {
    if (res.ok) {
      return res.json().then(json => {
        if (Env.isDevelopment()) {
          Cookies.set('current_user', json.access_token)
        } else {
          Cookies.set('current_user', json.access_token, {
            domain: 'middesk.com',
            secure: true
          })
        }

        return Promise.resolve(json)
      })
    }

    const error = new APIError(res)
    return res
      .json()
      .then((json: any) => {
        error.setJSON(json)

        return Promise.reject(error)
      })
      .catch(() => Promise.reject(error))
  }

  login(headers: any) {
    this.removeCurrentUser()

    return fetch(`${process.env.REACT_APP_API_HOST}/sessions`, {
      method: 'POST',
      headers: headers
    }).then(this.handleAccessToken)
  }

  ssoCallback({ code }: { code: string }) {
    this.removeCurrentUser()

    return fetch(
      `${process.env.REACT_APP_API_HOST}/sso/callback?code=${code}`,
      {
        method: 'POST'
      }
    ).then(this.handleAccessToken)
  }

  referralSignup({
    referral_code,
    email,
    company_name,
    consent
  }: {
    referral_code: string
    email: string
    company_name: string
    consent: boolean
  }) {
    this.removeCurrentUser()

    return fetch(
      `${process.env.REACT_APP_API_HOST}/v1/agent/partner_referral_links/${referral_code}/signup`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, company_name, consent })
      }
    ).then(this.handleAccessToken)
  }

  async logout() {
    await this.delete(`/sessions`)

    this.removeCurrentUser()
  }

  guest() {
    const options: {
      method: string
      headers: any
    } = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...this.auth,
        ...this.sandboxHeader('/guest/me')
      }
    }

    return fetch(`${process.env.REACT_APP_API_HOST}/guest/me`, options).then(
      res => {
        if (res.ok) {
          return res.json()
        }

        const error = new APIError(res)
        return res
          .json()
          .then((json: any) => {
            error.setJSON(json)

            return Promise.reject(error)
          })
          .catch(() => Promise.reject(error))
      }
    )
  }

  me() {
    const options: {
      method: string
      headers: any
    } = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...this.auth
      }
    }

    return fetch(`${process.env.REACT_APP_API_HOST}/me`, options).then(res => {
      if (res.ok) {
        return res.json()
      }

      const error = new APIError(res)
      return res
        .json()
        .then((json: any) => {
          error.setJSON(json)

          return Promise.reject(error)
        })
        .catch(() => Promise.reject(error))
    })
  }

  request(
    path: any,
    {
      method,
      body,
      headers
    }: { method?: string; body?: any; headers?: any } = {}
  ) {
    const options: {
      method: string
      headers: any
      body?: string
    } = {
      method: method || 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...this.auth,
        ...this.sandboxHeader(path),
        ...headers
      }
    }

    if (body && options.headers['Content-Type'] === 'application/json') {
      options.body = JSON.stringify(body)
    } else {
      options.body = body
    }

    return fetch(`${process.env.REACT_APP_API_HOST}${path}`, options).then(
      res => {
        if (!res.status || res.status === 401) {
          window.location.href = '/login'
          return
        }

        return res.ok ? this.onSuccess(res) : this.onError(res)
      }
    )
  }

  onSuccess(res: any) {
    if (res.status === 204) {
      return null
    }

    return res.json()
  }

  onError(res: any) {
    const error = new APIError(res)

    return res
      .json()
      .then((json: any) => {
        error.setJSON(json)

        return Promise.reject(error)
      })
      .catch(() => Promise.reject(error))
  }
}

export class APIError extends Error {
  status: number
  json?: any
  errors?: any
  messages?: any

  constructor(res: any) {
    super('API error')

    this.status = res.status
  }

  setJSON(value: any) {
    this.json = value
    this.errors = value['errors']
    this.messages = this.errors.map((err: any) => err.message)
  }
}

export default new API()
