import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { isArray, isObject, get } from 'lodash'
import lowerCase from 'lodash/lowerCase'
import { FieldArray } from 'formik'
import { Form, TextField } from '@middesk/components'
import { AuthContext } from '../contexts/AuthProvider'

import api from '../lib/api'
import {
  MOBILE_BUTTON_HEIGHT,
  STATE_MAP,
  SIGNATURE_EXCEPTION_ITEM_TYPE,
  DOCUMENT_EXCEPTION_ITEM_TYPE,
  ADDRESS_EXCEPTION_ITEM_TYPE,
  GUSTO_ACCOUNT_SLUG
} from '../lib/constants'

import { Address, InfoRequest, InfoRequestItem } from '../types'
import { COLORS } from '../components/System/Colors'
import SPACING from '../components/System/Spacing'
import {
  TYPOGRAPHY_SIZES,
  LargeDisplay,
  XLarge
} from '../components/System/Typography'
import Body from '../components/Body'
import OrderSubmittedIcon from '../components/System/Icons/OrderSubmitted'
import IconBubble from '../components/System/IconBubble'
import Link from '../components/System/Link'
import Button from '../components/Button'
import LoaderButton from '../components/LoaderButton'
import Middesk from '../components/System/Icons/Middesk'
import { BASE_64_IDENTIFIER } from '../lib/helpers'
import { CompanyDetailsInfoRequest } from '../components/InfoRequest/CompanyDetailsInfoRequest'
import { SignatureInfoRequest } from '../components/InfoRequest/SignatureInfoRequest'
import { RegistrationQuestionInfoRequest } from '../components/InfoRequest/RegistrationQuestionInfoRequest'
import { DocumentInfoRequest } from '../components/InfoRequest/DocumentInfoRequest'
import { theme } from '@middesk/components'
import { MAX_WIDTH_MOBILE } from '../components/Body'

const { typography, spacing, colors } = theme

type InfoRequestItemValue = {
  id: string
  value: string | Address | Array<string>
}

export type InfoRequestValues = {
  items: InfoRequestItemValue[]
  signatory: {
    name: string
    email: string
    title: string
    signature: string | undefined
    authorized: boolean
    is_current_user: boolean
  }
  attachment: string | undefined
}

type OutboundAttachmentValues = {
  attachment: string | null
}

const StyledBody = styled(Body)`
  padding: ${spacing.xxlarge} ${spacing.normal} ${spacing.normal}
    ${spacing.normal};

  h3:first-of-type {
    text-align: center;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  padding-left: ${SPACING.xlarge};
  margin-top: ${SPACING.xlarge};

  ${XLarge} {
    padding: 3px ${SPACING.small};
    color: ${COLORS.black};
  }
`

const StyledGustoLogo = styled.div`
  display: flex;
  padding-left: ${SPACING.xlarge};
  margin-top: ${SPACING.xlarge};
`

const InfoRequestCompleteMessageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${SPACING.medium};
  justify-content: center;
  text-align: center;
`

const SubmissionMessage = styled.span`
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
`

const ApplicationDetails = styled.div`
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.normal};
  margin-bottom: ${spacing.medium};
`

const InfoRequestItems = styled.div`
  > div:not(:last-child) {
    padding-bottom: ${SPACING.medium};
  }
`

const StyledDescription = styled.div`
  h1,
  h2,
  h3 {
    margin: 10px 0;
    text-align: inherit !important;
  }

  ul,
  ol,
  p {
    color: ${COLORS.black};
    margin: 5px 0;
  }
`

export const ButtonContainer = styled.div`
  margin-top: ${spacing.normal};
  text-align: end;

  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    background: ${colors.white};
    width: 100%;
    text-align: center;
  }

  input {
    @media (max-width: ${MAX_WIDTH_MOBILE}) {
      width: 100%;
      height: ${MOBILE_BUTTON_HEIGHT};
      max-height: ${MOBILE_BUTTON_HEIGHT};
    }
  }
`

const PartnerLogo = styled.img`
  max-width: calc(${MAX_WIDTH_MOBILE} - 66px);
  max-height: 60px;
`

export const DescriptionNote = ({
  description
}: {
  description: string
}): JSX.Element => {
  return (
    <StyledDescription
      dangerouslySetInnerHTML={{ __html: `<div>${description}</div>` }}
    />
  )
}

export const OutboundAttachment = ({
  attachment
}: OutboundAttachmentValues) => {
  if (!attachment) {
    return null
  }

  return (
    <Link href={attachment} target='_blank'>
      View attachment
    </Link>
  )
}

const GenericSubmittedMessage = () => {
  return (
    <InfoRequestCompleteMessageContainer>
      <IconBubble variant='green'>
        <OrderSubmittedIcon />
      </IconBubble>
      <LargeDisplay>Request already completed</LargeDisplay>
      <SubmissionMessage>
        The information requested has already been provided and there is no
        further action required.
      </SubmissionMessage>
    </InfoRequestCompleteMessageContainer>
  )
}

const InfoRequestSubmitted = ({
  companyId,
  hideOutstandingActions
}: {
  companyId: string
  hideOutstandingActions: boolean
}) => {
  return (
    <InfoRequestCompleteMessageContainer>
      <IconBubble variant='green'>
        <OrderSubmittedIcon />
      </IconBubble>
      <LargeDisplay>You&apos;re all set!</LargeDisplay>
      <SubmissionMessage>
        Thank you for submitting the information! We&apos;ll continue
        registering your business.
      </SubmissionMessage>
      {!hideOutstandingActions && (
        <Link to={`/companies/${companyId}/actions`}>
          <Button>View outstanding actions</Button>
        </Link>
      )}
    </InfoRequestCompleteMessageContainer>
  )
}

const InfoRequestVoided = ({
  companyId,
  hideOutstandingActions
}: {
  companyId: string
  hideOutstandingActions: boolean
}) => {
  return (
    <InfoRequestCompleteMessageContainer>
      <IconBubble variant='green'>
        <OrderSubmittedIcon />
      </IconBubble>
      <LargeDisplay>You&apos;re all set!</LargeDisplay>
      <SubmissionMessage>
        Our team no longer needs this information to submit your state filing.
      </SubmissionMessage>
      {!hideOutstandingActions && (
        <Link to={`/companies/${companyId}/actions`}>
          <Button>View outstanding actions</Button>
        </Link>
      )}
    </InfoRequestCompleteMessageContainer>
  )
}

const findSignatureExceptionItem = (
  exception: InfoRequest | undefined
): InfoRequestItem | undefined => {
  return findExceptionItem(exception, SIGNATURE_EXCEPTION_ITEM_TYPE)
}

const findDocumentExceptionItem = (
  exception: InfoRequest | undefined
): InfoRequestItem | undefined => {
  return findExceptionItem(exception, DOCUMENT_EXCEPTION_ITEM_TYPE)
}

const findCompanyAddressExceptionItem = (
  exception: InfoRequest | undefined
): InfoRequestItem | undefined => {
  return findExceptionItem(exception, ADDRESS_EXCEPTION_ITEM_TYPE)
}

const findExceptionItem = (
  exception: InfoRequest | undefined,
  exceptionType: string
): InfoRequestItem | undefined => {
  return exception?.items.find(
    item => item.exception_item_type === exceptionType
  )
}

const EMPTY_VALUES: InfoRequestValues = {
  items: [],
  signatory: {
    name: '',
    email: '',
    title: '',
    signature: '',
    authorized: false,
    is_current_user: false
  },
  attachment: ''
}

const InfoRequestForm = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [infoRequest, setInfoRequest] = useState<InfoRequest>()
  const [completed, setCompleted] = useState(false)
  const [voided, setVoided] = useState(false)
  const [initialValues, setInitialValues] = useState<InfoRequestValues>(
    EMPTY_VALUES
  )
  const [disabled, setDisabled] = useState(true)
  const { push } = useHistory()
  const { setCustomTheme, computeTheme } = useContext(AuthContext)

  useEffect(() => {
    api
      .get(`/v1/agent/info_requests/${id}`)
      .then(data => {
        setInfoRequest(data)

        const items: Array<{ id: string; value: string }> = []
        data.items &&
          data.items.map((item: InfoRequestItem) => {
            const newItem = { id: item.id, value: '' }
            items.push(newItem)
          })

        if (items) {
          const tempObj = EMPTY_VALUES
          tempObj.items = items

          setInitialValues(tempObj)
        }

        if (data.completed_at) {
          setCompleted(true)
        }
        if (data.voided_at) {
          setVoided(true)
        }
      })
      .catch(e => {
        if (e.status === 403) {
          setCompleted(true)
        } else if (e.status === 404) {
          push('/home')
        }
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    setCustomTheme(
      computeTheme({
        brand_primary_color: infoRequest?.partner?.brand_primary_color,
        brand_secondary_color: infoRequest?.partner?.brand_secondary_color
      })
    )
  }, [infoRequest])

  const formatObjectForSubmit = (value: any) => {
    if (value?.full_address) return value.full_address

    if (value.address_line1) {
      return `${value.address_line1}${
        value.address_line2 ? ` ${value.address_line2}` : ''
      } ${value.city}, ${value.state} ${value.postal_code}`
    }

    return JSON.stringify(value)
  }

  const formatItemForSubmit = (item: InfoRequestItemValue) => {
    const value = item.value
    let formattedValue = null
    if (isArray(value)) formattedValue = value.join(', ')
    else if (isObject(value)) formattedValue = formatObjectForSubmit(value)
    else formattedValue = value
    return {
      ...item,
      value: formattedValue
    }
  }

  const onSubmit = (values: InfoRequestValues) => {
    setSubmitting(true)

    const signatureExceptionItem = findSignatureExceptionItem(infoRequest)
    const documentExceptionItem = findDocumentExceptionItem(infoRequest)
    const companyAddressExceptionItem = findCompanyAddressExceptionItem(
      infoRequest
    )
    let formattedItems = []

    if (signatureExceptionItem !== undefined) {
      formattedItems = [signatureExceptionItem]
    } else if (documentExceptionItem !== undefined) {
      formattedItems = [documentExceptionItem]
    } else {
      formattedItems = values.items.map(item =>
        item.id == companyAddressExceptionItem?.id
          ? item
          : formatItemForSubmit(item)
      )
    }

    api
      .patch(`/v1/agent/info_requests/${id}`, {
        items: formattedItems,
        attachment: (
          values.signatory.signature ||
          values.attachment ||
          ''
        ).replace(BASE_64_IDENTIFIER, '')
      })
      .then(() => {
        setCompleted(true)
      })
      .finally(() => setSubmitting(false))
  }

  let title = `Submit information for ${infoRequest?.owner.legal_name}`
  const signatureExceptionItem = findSignatureExceptionItem(infoRequest)
  const documentExceptionItem = findDocumentExceptionItem(infoRequest)
  const partnerLogo = infoRequest?.partner?.logo
  const gustoInfoRequest = infoRequest?.partner?.slug === GUSTO_ACCOUNT_SLUG

  let formContent = <></>
  if (infoRequest?.owner?.owner_type === 'Agent::CompanyDetails') {
    formContent = (
      <>
        <FieldArray
          name='items'
          render={() => (
            <>
              <InfoRequestItems>
                {infoRequest?.items.map((item, i) => (
                  <CompanyDetailsInfoRequest key={i} item={item} index={i} />
                ))}
              </InfoRequestItems>
            </>
          )}
        />
      </>
    )
  } else if (documentExceptionItem !== undefined) {
    title = `Submit document for ${infoRequest?.owner.legal_name}`

    formContent = (
      <>
        <DocumentInfoRequest
          exceptionItem={documentExceptionItem}
          submitting={submitting}
        />
      </>
    )
  } else if (infoRequest?.owner?.registration) {
    formContent = (
      <>
        <FieldArray
          name='items'
          render={() => (
            <>
              <InfoRequestItems>
                {infoRequest?.items.map((item, i) =>
                  item.owner_field ? (
                    <RegistrationQuestionInfoRequest
                      key={i}
                      item={item}
                      index={i}
                    />
                  ) : (
                    <div key={item.id}>
                      <TextField
                        name={`items[${i}].value`}
                        label={item.title}
                        placeholder={item.placeholder}
                      />
                      <DescriptionNote description={item.description} />
                      <OutboundAttachment
                        attachment={item.outbound_attachment}
                      />
                    </div>
                  )
                )}
              </InfoRequestItems>
            </>
          )}
        />
      </>
    )
  } else if (signatureExceptionItem !== undefined) {
    title = `Submit signature for ${infoRequest?.owner.legal_name}`

    formContent = (
      <>
        <SignatureInfoRequest
          {...{
            signatureExceptionItem,
            submitting,
            gustoInfoRequest,
            name: infoRequest?.owner?.name
          }}
        />
      </>
    )
  } else {
    formContent = (
      <>
        <FieldArray
          name='items'
          render={() => (
            <>
              <InfoRequestItems>
                {infoRequest?.items.map((item, i) => {
                  return (
                    <div key={item.id}>
                      <TextField
                        name={`items[${i}].value`}
                        label={item.title}
                        placeholder={item.placeholder}
                      />
                      <DescriptionNote description={item.description} />
                      <OutboundAttachment
                        attachment={item.outbound_attachment}
                      />
                    </div>
                  )
                })}
              </InfoRequestItems>
            </>
          )}
        />
      </>
    )
  }

  if (
    infoRequest?.owner?.owner_type === 'Agent::AgencyRegistrationLocalDetails'
  ) {
    title = `Submit information for ${infoRequest.owner.jurisdiction_name} registration`
  }

  const datePartItems: { [item_id: string]: string[] } = {}
  ;(infoRequest?.items || []).forEach(item => {
    if (item.question && item.question.type === 'date_part') {
      datePartItems[item.id] = item.question.components || []
    }
  })

  const onChange = (values: InfoRequestValues) => {
    // Disable if any item is without a value set
    if (values.items.find(item => item.value === '')) {
      setDisabled(true)
      return
    }

    // Disable if any date part question is missing any type
    const datePartValid = Object.keys(datePartItems).every(datePartItemId => {
      const item = values.items.find(item => item.id === datePartItemId)

      if (!item) {
        return false
      }

      return datePartItems[datePartItemId].every(component => {
        return !!get(item.value, component)
      })
    })

    if (!datePartValid) {
      setDisabled(true)
      return
    }

    setDisabled(false)
  }

  const logo =
    gustoInfoRequest && partnerLogo ? (
      <StyledGustoLogo>
        <PartnerLogo src={partnerLogo} />
      </StyledGustoLogo>
    ) : (
      <StyledLink
        to={
          infoRequest?.account.agent_branding_options.whitelabel
            ? undefined
            : '/'
        }
        disabled={infoRequest?.account.agent_branding_options.whitelabel}
      >
        {partnerLogo ? <PartnerLogo src={partnerLogo} /> : <Middesk />}
      </StyledLink>
    )

  return (
    <>
      {!loading && logo}
      {!loading && !infoRequest && completed && <GenericSubmittedMessage />}
      {!loading && infoRequest && (
        <>
          <StyledBody
            title={!(completed || voided) ? title : ''}
            background={colors.white}
          >
            {completed ? (
              <InfoRequestSubmitted
                companyId={infoRequest.company_id}
                hideOutstandingActions={
                  gustoInfoRequest ||
                  infoRequest.account.agent_branding_options.whitelabel
                }
              />
            ) : voided ? (
              <InfoRequestVoided
                companyId={infoRequest.company_id}
                hideOutstandingActions={
                  gustoInfoRequest ||
                  infoRequest.account.agent_branding_options.whitelabel
                }
              />
            ) : (
              <>
                {infoRequest?.owner.application_type && (
                  <ApplicationDetails>
                    {`${STATE_MAP[infoRequest?.owner.state].name} ${lowerCase(
                      infoRequest?.owner.application_type
                    )}`}
                  </ApplicationDetails>
                )}
                {infoRequest?.owner.owner_type == 'Agent::AnnualFiling' && (
                  <ApplicationDetails>
                    {`${
                      STATE_MAP[infoRequest?.owner.state].name
                    } annual report`}
                  </ApplicationDetails>
                )}
              </>
            )}
            {!(completed || voided) ? (
              <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                onChange={onChange}
              >
                {formContent}
                {signatureExceptionItem === undefined &&
                documentExceptionItem === undefined ? (
                  <ButtonContainer>
                    <LoaderButton
                      {...{
                        isDisabled: disabled || submitting,
                        submitting: submitting,
                        submitText: 'Submit'
                      }}
                    />
                  </ButtonContainer>
                ) : (
                  <></>
                )}
              </Form>
            ) : (
              <></>
            )}
          </StyledBody>
        </>
      )}
    </>
  )
}

export default InfoRequestForm
